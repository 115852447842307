import * as React from "react";
import ImagePicker from "./ImagePicker";
import Divider from "@mui/material/Divider";
const ConfigGeneral = (props) => {
  return (
    <div className="">
      <div className="flex flex-col text-black">
        <span className="text-base-2 mb-2">Site name</span>
        <span className="font-normal mb-4">
          A site name is a unique identifier for a website, aiding in easy
          organization and navigation within the dashboard.
        </span>
        <input
          className="border rounded border-catelina-blue/60 w-full py-1 px-2 md:w-80"
          placeholder="Website name"
          value={props.siteName}
        //   disabled
          onChange={(e) => {
            props.onDataChange({ siteName: e.target.value });
          }}
        />
      </div>
      <div className="py-8">
        <Divider />
      </div>

      <ImagePicker
        image_url={props.faviconImage}
        siteId={props.siteId}
        title="Favicon image"
        desc="Favicon is displayed on your site's tab in a browser."
        notes="Please upload a 16x16px .PNG or .JPG image."
        onImageChange={(img_url) => {
          props.onDataChange({ faviconImage: img_url });
        }}
      />
      <div className="py-8">
        <Divider />
      </div>

      <ImagePicker
        siteId={props.siteId}
        image_url={props.defaultSocialMediaImage}
        title="Default social media image (Open graph image)"
        desc="Open Graph images, also known as OG images, are designated images used to represent webpages when shared on social media platforms. 
They provide a visual representation of the content and help maintain a consistent and visually appealing appearance across different social media channels."
        notes="Please upload a 1200x630px .PNG or .JPG image."
        onImageChange={(img_url) => {
          props.onDataChange({ defaultSocialMediaImage: img_url });
        }}
      />
    </div>
  );
};

export default ConfigGeneral;
