import * as React from "react";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useLocation } from "react-router-dom";
import PrivacyPolicy from "../components/PrivacyPolicy";
import { Navbar } from "../components/Layout/Navbar";

export const PrivacyPolicyPage = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const stateData = location.state;
  const [cookie, setCookie, removeCookie] = useCookies(["rp_user_data"]);

  return (
    <>
      <Helmet>
        <title>RocketPages - Privacy policy</title>
        <meta
          name="description"
          content="Build and Launch Your Free Website in Just 15 Minutes"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&display=swap"
          rel="stylesheet"
        />
        <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <Navbar {...stateData} skipCheckCookies={true} />
      <PrivacyPolicy />

    </>
  );
};
