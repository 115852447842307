import * as React from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ImageNotSupportedOutlinedIcon from "@mui/icons-material/ImageNotSupportedOutlined";
import axiosInstance from "../../axiosInstance";
import axios from "axios";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const ImagePicker = (props) => {
  const [file, setFile] = React.useState(props.image_url);
  const inputFile = React.useRef(null);
  const [isLoading, setIsLoading] = React.useState(false);
  React.useEffect(() => {
    setFile(props.image_url);
    // console.log('props.image_url', props.image_url);
  }, [props.image_url]);

  const handleChange = async (e) => {
    setIsLoading(true);
    try {
      let formData = new FormData(); // instantiate it
      // suppose you have your file ready
      formData.set("file", e.target.files[0]);
      formData.set("siteId", props.siteId);

      // formData.set('data1', dataInputField1); // suppose you got dataInputField1 from your HTML5 form input
      // console.log("axiosInstance", axiosInstance);
      // const axiosI = new axios.create({
      //   baseURL: process.env.API_ENDPOINT,
      // });
      let res = await axios.post(
        `${
          process.env.API_ENDPOINT === ""
            ? "http://localhost:3000"
            : process.env.API_ENDPOINT
        }/${process.env.IMAGE_UPLOAD_PATH}`,
        formData,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
          maxRedirects: 200,
        }
      );
      setFile(res.data.url);
      setIsLoading(false);
      props.onImageChange(res.data.url);
      e.target.value = "";
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col text-black">
      {props.title && <span className="text-base-2 mb-2">{props.title}</span>}
      {props.desc && <span className="font-normal mb-2">{props.desc}</span>}
      <div
        className={`w-full border border-catelina-blue/60 rounded flex flex-col ${
          props.size === "small"
            ? "h-40 md:w-48"
            : props.size === "full"
            ? "w-full"
            : "h-60 md:w-80"
        } `}
      >
        <div className="border-b border-catelina-blue/60  h-10 flex flex-row justify-end items-center px-2">
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <input
            className="hidden"
            type="file"
            onChange={handleChange}
            ref={inputFile}
          />
          <EditNoteOutlinedIcon
            className="text-catelina-blue mr-2 hover:cursor-pointer"
            fontSize="small"
            onClick={() => inputFile.current.click()}
          />
          <DeleteOutlineOutlinedIcon
            className="text-catelina-blue hover:cursor-pointer"
            fontSize="small"
            onClick={() => {
              setFile("");
              props.onImageChange && props.onImageChange("");
            }}
          />
        </div>
        <div className="h-full flex justify-center items-center px-4">
          {file !== "" && <img className="h-52 object-contain" src={file} />}
          {(file === "" || file === undefined) && (
            <ImageNotSupportedOutlinedIcon className="" fontSize="large" />
          )}
        </div>
      </div>
      {props.notes && (
        <div className="items-center text-xsm">
          <InfoOutlinedIcon fontSize="inherit" />

          <span className="ml-1">{props.notes}</span>
        </div>
      )}
    </div>
  );
};

export default ImagePicker;
