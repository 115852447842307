import React, { useState } from "react";

interface AccordionItem {
  title: string;
  content: string;
}

const DNSProviders: React.FC = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const toggleAccordion = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const items: AccordionItem[] = [
    {
      title: 'GoDaddy',
      content: `<ol>
              <li>
                <b>Sign In to GoDaddy:</b>
                <ul>
                  <li>Log in to your GoDaddy account.</li>
                </ul>
              </li>
              <li>
                <b>Access Domain Settings:</b>
                <ul>
                  <li>Navigate to "My Products."</li>
                  <li>Find your domain and click on "DNS" or "Manage."</li>
                </ul>
              </li>
              <li>
                <b>Add a New A Record:</b>
                <ul>
                  <li>Scroll down to the "Records" section.</li>
                  <li>Click "Add" to create a new DNS record.</li>
                  <li>Select "A" as the record type.</li>
                  <li>
                    Enter the following details:
                    <ul className="list-square">
                      <li>
                        <b>Name:</b> "[YourDomainName]"
                      </li>
                      <li>
                        <b>Value:</b> 146.190.156.136
                      </li>
                      <li>
                        <b>TTL:</b> Default
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                <b>Update an Existing A Record:</b>
                <ul>
                  <li>Find the existing A record you want to update.</li>
                  <li>Click the pencil icon next to the record to edit it.</li>
                  <li>
                    Update the details as needed (e.g., change the IP address in
                    the "Value" field to 146.190.156.136).
                  </li>
                  <li>Click "Save."</li>
                </ul>
              </li>
            </ol>`
    },
    {
      title: 'Cloudflare',
      content: `<ol>
              <li>
                <b>Sign In to Cloudflare:</b>
                <ul>
                  <li>Log in to your Cloudflare account.</li>
                </ul>
              </li>
              <li>
                <b>Access DNS Settings:</b>
                <ul>
                  <li>Select the domain you want to manage.</li>
                  <li>Click on the "DNS" tab.</li>
                </ul>
              </li>
              <li>
                <b>Add a New A Record:</b>
                <ul>
                  <li>Click "Add record."</li>
                  <li>Select "A" as the record type.</li>
                  <li>
                    Enter the following details:
                    <ul className="list-square">
                      <li>
                        <b>Name:</b> [YourDomainName]
                      </li>
                      <li>
                        <b>IPv4 address:</b> 146.190.156.136
                      </li>
                      <li>
                        <b>TTL:</b> Auto
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                <b>Update an Existing A Record:</b>
                <ul>
                  <li>Find the existing A record you want to update.</li>
                  <li>Click on the record to edit it.</li>
                  <li>
                    Update the details as needed (e.g., change the IP address in
                    the "IPv4 address" field to 146.190.156.136).
                  </li>
                  <li>Click "Save."</li>
                </ul>
              </li>
            </ol>`
    },
    {
      title: 'Amazon Route 53',
      content: `<ol>
              <li>
                <b>Sign In to AWS Management Console:</b>
                <ul>
                  <li>Go to the AWS Management Console and log in.</li>
                </ul>
              </li>
              <li>
                <b>Access Route 53:</b>
                <ul>
                  <li>Navigate to Amazon Route 53.</li>
                  <li>Click on "Hosted Zones."</li>
                </ul>
              </li>
              <li>
                <b>Select Domain:</b>
                <ul>
                  <li>Choose the domain you want to manage.</li>
                </ul>
              </li>
              <li>
                <b>Add a New A Record:</b>
                <ul>
                  <li>Click "Create Record Set."</li>
                  <li>
                    Enter the following details:
                    <ul className="list-square">
                      <li>
                        <b>Name:</b> [YourDomainName]
                      </li>
                      <li>
                        <b>Type:</b> A - IPv4 address
                      </li>
                      <li>
                        <b>Value:</b> 146.190.156.136
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                <b>Update an Existing A Record:</b>
                <ul>
                  <li>Find the existing A record you want to update.</li>
                  <li>Click on the record to edit it.</li>
                  <li>
                    Update the details as needed (e.g., change the IP address in
                    the "Value" field to 146.190.156.136).
                  </li>
                  <li>Click "Save Record Set."</li>
                </ul>
              </li>
            </ol>`
    },
    {
      title: 'Google Domains',
      content: `<ol>
              <li>
                <b>Sign In to Google Domains:</b>
                <ul>
                  <li>Go to Google Domains and log in to your account.</li>
                </ul>
              </li>
              <li>
                <b>Manage Domain:</b>
                <ul>
                  <li>Locate your domain and click on "Manage" or "DNS."</li>
                </ul>
              </li>
              <li>
                <b>Add a New A Record:</b>
                <ul>
                  <li>Scroll down to the "Custom resource records" section.</li>
                  <li>
                    Enter the following details:
                    <ul className="list-square">
                      <li>
                        <b>Name:</b> [YourDomainName]
                      </li>
                      <li>
                        <b>Type:</b> A
                      </li>
                      <li>
                        <b>TTL:</b> 1h
                      </li>
                      <li>
                        <b>Data:</b> 146.190.156.136
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                <b>Update an Existing A Record:</b>
                <ul>
                  <li>Find the existing A record you want to update.</li>
                  <li>
                    Click on the pencil icon next to the record to edit it.
                  </li>
                  <li>
                    Update the details as needed (e.g., change the IP address in
                    the "Data" field to 146.190.156.136).
                  </li>
                  <li>Click "Save."</li>
                </ul>
              </li>
            </ol>`
    },
    {
      title: 'Namecheap',
      content: `<ol>
              <li>
                <b>Sign In to Namecheap:</b>
                <ul>
                  <li>Log in to your Namecheap account.</li>
                </ul>
              </li>
              <li>
                <b>Manage Domain:</b>
                <ul>
                  <li>Select the domain you want to manage.</li>
                  <li>Click on "Manage" next to the domain.</li>
                </ul>
              </li>
              <li>
                <b>Access Advanced DNS:</b>
                <ul>
                  <li>Click on the "Advanced DNS" tab.</li>
                </ul>
              </li>
              <li>
                <b>Add a New A Record:</b>
                <ul>
                  <li>Scroll down to the "Host Records" section.</li>
                  <li>Click "Add New Record."</li>
                  <li>Select "A Record" as the type.</li>
                  <li>
                    Enter the following details:
                    <ul className="list-square">
                      <li>
                        <b>Host Record:</b> [YourDomainName]
                      </li>
                      <li>
                        <b>Points To:</b> 146.190.156.136
                      </li>
                      <li>
                        <b>TTL:</b> Automatic
                      </li>
                    </ul>
                  </li>
                  <li>Click the checkmark to save.</li>
                </ul>
              </li>
              <li>
                <b>Update an Existing A Record:</b>
                <ul>
                  <li>Find the existing A record you want to update.</li>
                  <li>
                    Click on the pencil icon next to the record to edit it.
                  </li>
                  <li>
                    Update the details as needed (e.g., change the IP address in
                    the "IP Address" field to 146.190.156.136).
                  </li>
                  <li>Click the checkmark to save.</li>
                </ul>
              </li>
            </ol>`
    },
    {
      title: 'Bluehost',
      content: `<ol>
              <li>
                <b>Sign In to Namecheap:</b>
                <ul>
                  <li>Log in to your Bluehost account.</li>
                </ul>
              </li>
              <li>
                <b>Access Domain Manager:</b>
                <ul>
                  <li>
                    From the Bluehost dashboard, click on "Domains" and then "My
                    Domains."
                  </li>
                </ul>
              </li>
              <li>
                <b>Manage DNS:</b>
                <ul>
                  <li>
                    Click the "Manage" button next to the domain you want to
                    update.
                  </li>
                </ul>
              </li>
              <li>
                <b>Add a New A Record:</b>
                <ul>
                  <li>Go to the "DNS" tab.</li>
                  <li>Scroll down to the "Add DNS Record" section.</li>
                  <li>Select "A" as the record type.</li>
                  <li>
                    Enter the following details:
                    <ul className="list-square">
                      <li>
                        <b>Host Record:</b> [YourDomainName]
                      </li>
                      <li>
                        <b>Points To:</b> 146.190.156.136
                      </li>
                      <li>
                        <b>TTL:</b> Default
                      </li>
                    </ul>
                  </li>
                  <li>Click "Save."</li>
                </ul>
              </li>
              <li>
                <b>Update an Existing A Record:</b>
                <ul>
                  <li>Find the existing A record you want to update.</li>
                  <li>Click "Edit" next to the record.</li>
                  <li>
                    Update the details as needed (e.g., change the IP address in
                    the "Points To" field to 146.190.156.136).
                  </li>
                  <li>Click "Save."</li>
                </ul>
              </li>
            </ol>`
    },
    {
      title: 'HostGator',
      content: `<ol>
              <li>
                <b>Sign In to HostGator:</b>
                <ul>
                  <li>Log in to your HostGator account.</li>
                </ul>
              </li>
              <li>
                <b>Access Domain Manager:</b>
                <ul>
                  <li>
                    From the HostGator dashboard, click on "Domains" and then
                    "My Domains."
                  </li>
                </ul>
              </li>
              <li>
                <b>Manage DNS:</b>
                <ul>
                  <li>
                    Click the "Manage" button next to the domain you want to
                    update.
                  </li>
                </ul>
              </li>
              <li>
                <b>Add a New A Record:</b>
                <ul>
                  <li>Go to the "DNS" tab.</li>
                  <li>Scroll down to the "Add DNS Record" section.</li>
                  <li>Select "A" as the record type.</li>
                  <li>
                    Enter the following details:
                    <ul className="list-square">
                      <li>
                        <b>Host Record:</b> [YourDomainName]
                      </li>
                      <li>
                        <b>Points To:</b> 146.190.156.136
                      </li>
                      <li>
                        <b>TTL:</b> Default
                      </li>
                    </ul>
                  </li>
                  <li>Click "Save."</li>
                </ul>
              </li>
              <li>
                <b>Update an Existing A Record:</b>
                <ul>
                  <li>Find the existing A record you want to update.</li>
                  <li>Click "Edit" next to the record.</li>
                  <li>
                    Update the details as needed (e.g., change the IP address in
                    the "Points To" field to 146.190.156.136).
                  </li>
                  <li>Click "Save."</li>
                </ul>
              </li>
            </ol>`
    },
  ];

  return (
    <>

        <ul className="border border-gray-200 rounded-lg list-none p-0 mt-2">
          {items.map((item, index) => (
            <li key={index} className="border-b border-gray-200">
              <button
                onClick={() => toggleAccordion(index)}
                className="accordion-header w-full text-left px-4 py-2 focus:outline-none focus:ring focus:ring-gray-300"
              >
                <span>{item.title}</span>
              </button>
              {openIndex === index && (
                <div className="accordion-content px-4 py-2 font-normal">
                  <div dangerouslySetInnerHTML={{ __html: item.content }} />
                </div>
              )}
            </li>
          ))}
        </ul>

    </>
  );
};

export default DNSProviders;
