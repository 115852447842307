import Divider from "@mui/material/Divider";
import * as React from "react";
const ConfigCustomCode = (props) => {
  return (
    <div className="flex flex-col text-black">
      <span className="text-base-2 mb-2">{"<head> custom HTML code"}</span>
      <span className="font-normal mb-2">
        {
          "Include HTML code to your website <head>. You can make use of <script> and <style> HTML tags too."
        }
      </span>
      <div className="flex flex-row">
        <textarea
          value={props.head_customHtmlCode}
          onChange={(e) => {
            props.onDataChange({head_customHtmlCode: e.target.value})
          }}
          className="w-full lg:w-4/5 border rounded border-catelina-blue/60 py-1 px-2"
          placeholder="<script src=”https;//www.googletagmanager.com/gtag/js?id=UA-XXX-X”></script>"
          rows={8}
        />
      </div>
      <div className="py-8">
        <Divider />
      </div>
      <span className="text-base-2 mb-2">{"<body> custom HTML code"}</span>
      <span className="font-normal mb-2">
        {
          "Include HTML code to your website <body>. This code will be appended at the last of body tag."
        }
      </span>
      <div className="flex flex-row">
        <textarea
          value={props.body_customHtmlCode}
          onChange={(e) => {
            props.onDataChange({body_customHtmlCode: e.target.value})
          }}
          className="w-full lg:w-4/5 border rounded border-catelina-blue/60 py-1 px-2"
          placeholder="<script src=”https;//www.googletagmanager.com/gtag/js?id=UA-XXX-X”></script>"
          rows={8}
        />
      </div>
    </div>
  );
};

export default ConfigCustomCode;
