import Button from "@mui/material/Button";
import * as React from "react";
import axiosInstance from "../../axiosInstance";
import moment from 'moment';
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";

type Person = {
  id: string;
  firstName: string;
  lastName: string;
  message: string;
  email: string;
  phoneNumber: string;
  created_at: string;
};

const ConfigContactForm = (props: any) => {
  const [data, setData] = React.useState<Person[]>([]);
  const [subscriptions, setSubscriptions] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  async function fetchSubscriptionsData() {
    setLoading(true);
    try {
      let res = await axiosInstance.get(
        `/api/configs/site-config-get-subscriptions?siteId=${props.siteId}`
      );
      setSubscriptions(res.data.data);
    } catch (err) {
      console.log(err);
    }
    finally {
      setLoading(false);
    }
  }

  async function fetchData(siteId) {
    try {
      let response = await axiosInstance.get(
        `/api/all-form-submissions?siteId=${siteId}`
      );
      setData(response.data.data);
    }
    catch (err) {
      console.log(err);
    }
  }

  React.useEffect(() => {
    fetchData(props.siteId);
    fetchSubscriptionsData();
  }, [props.siteId]);

  const displayData = subscriptions.length === 0 && data.length > 5 ? data.slice(0, 5) : data;
  //const displayData = data;

  return (
    <div className="flex flex-col text-black h-screen flex-1 md:mr-4 mt-2.5 md:mt-0">
      <span className="text-base-2 mb-2">{loading ? 'Loading...' : `${displayData.length} Response(s)`}</span>

      { !loading && subscriptions.length === 0 && data.length > 5 && (
        <div className="flex flex-col md:flex-row justify-between items-center mt-3 mb-2">
        <span className="text-base-3 text-red-500">
        <ErrorOutlineIcon
                        fontSize="small"
                        sx={{ color: "red" }}
                      />You have reached the limit of 5 contact form submissions on the Free Plan. Upgrade to view all submissions.</span>
          <Button
            size="small"
            className="h-8 pr-8 pl-8 mt-2 md:mt-0 whitespace-nowrap"
            variant="contained"
            sx={{
              textTransform: "none",
            }}
            onClick={() => {
              let _url = `/site-settings/${props.siteId}?section=Subscriptions`;
              window.open(_url, "_self")?.focus();
            }}
           >
            View Plans
          </Button></div>
      )}


      <div className="overflow-x-auto">
        <table className="w-full border-collapse font-body rounded bg-white mb-0">
          <thead>
            <tr>
              <th className="text-left w-1/6 px-2 py-1 border text-base">Submitted</th>
              <th className="text-left w-1/6 px-2 py-1 border text-base">First Name</th>
              <th className="text-left w-1/6 px-2 py-1 border text-base">Last Name</th>
              <th className="text-left w-1/6 px-2 py-1 border text-base">Email</th>
              <th className="text-left w-1/6 px-2 py-1 border text-base">Phone Number</th>
              <th className="text-left w-1/6 min-w-[300px] px-2 py-1 border text-base">Message</th>
            </tr>
          </thead>
          <tbody>
            {!loading && displayData.map((row: any) => {
              const timestamp = row.created_at; // Replace this with your timestamp
              const date = new Date(timestamp);

              // const formattedDate = date.toLocaleDateString("en-US", {
              //   year: "numeric",
              //   month: "short",
              //   day: "numeric",
              //   hour: "numeric",
              //   minute: "numeric",
              //   second: "numeric",
              //   timeZone: "UTC", // Adjust timezone as needed
              // });

              return (
                <tr key={row.id}>
                  <td className="text-left px-2 py-1 border text-xsm">
                    {moment(date).format("HH:mm:ss, DD-MM-YYYY")}
                  </td>
                  <td className="text-left px-2 py-1 border text-xsm">
                    {row.firstName}
                  </td>
                  <td className="text-left px-2 py-1 border text-xsm">
                    {row.lastName}
                  </td>
                  <td className="text-left px-2 py-1 border text-xsm">
                    {row.email}
                  </td>
                  <td className="text-left px-2 py-1 border text-xsm">
                    {row.phoneNumber}
                  </td>
                  <td className="text-left px-2 py-1 border text-xsm">
                    {row.message}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ConfigContactForm;
