import * as React from "react";
import ImagePicker from "./ImagePicker";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import { MultiSelect } from "react-multi-select-component";
import { TagsInput } from "react-tag-input-component";
import shortUUID from 'short-uuid';

//people.sort((a, b) => a.name.localeCompare(b.name));
const ConfigAdministration = (props) => {
  React.useEffect(() => {
    setSelected(
      (props.data.templateCategories || [])?.map((lk) => ({
        label: lk,
        value: lk,
      }))
    );
  }, [props.data.templateCategories]);
  const [selected, setSelected] = React.useState([]);
  const colorPalletKeys = shortUUID();
  // console.log("ConfigAdministration.data", props.data);
  return (
    <div className="">
      <div className="flex flex-col text-black">
        <span className="text-base-2 mb-2">Template name</span>
        <input
          className="border rounded border-catelina-blue/60 w-full py-1 px-2 md:w-80"
          placeholder="Website name"
          value={props.data.templateName || props.data.siteName}
          //   disabled
          onChange={(e) => {
            props.onDataChange({ templateName: e.target.value });
          }}
        />
        {props.data && (
          <FormControl variant="standard">
            <FormControlLabel
              control={
                <Switch
                  defaultChecked={props.data.isTemplate === "true"}
                  checked={props.data.isTemplate === "true"}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    props.onDataChange({ isTemplate: event.target.checked ? "true" : "false" });
                    // setSelected(event.target.checked)
                  }}
                  name="isTemplate"
                />
              }
              label="Is Template"
            />
            {/* </FormGroup> */}
          </FormControl>
        )}
      </div>
      <div className="py-8">
        <Divider />
      </div>

      <div className="flex flex-col text-black">
        <span className="text-base-2 mb-2">Template Categories</span>
        <MultiSelect
          options={props.templateSiteCategories
            .map((ol) => {
              return { label: ol.name, value: ol.name };
            })
            .sort((a, b) =>
              a.label.localeCompare(b.label, undefined, {
                numeric: true,
                sensitivity: "base",
              })
            )}
          value={selected}
          // value={props.data.templateCategories || []}
          onChange={(vals) => {
            // console.log(
            //   "sasasdas",
            //   vals.map((kk) => kk.label)
            // );
            setSelected(vals);
            props.onDataChange({
              templateCategories: vals.map((kk) => kk.label),
            });
          }}
          labelledBy="Template Categories"
        />
        {/* <TagsInput
          // beforeAddValidate={(tag: string, existingTags: string[]) => false}
          value={props.data.templateCategories}
          onChange={(vals) => {
            props.onDataChange({ templateCategories: vals });
            // console.log('TagsInput',vals)
          }}
          name="category"
          placeHolder="enter category"
        /> */}
      </div>
      <div className="py-8">
        <Divider />
      </div>

      <div className="flex flex-col text-black">
        <div className="flex flex-col md:flex-row text-center mb-4">
          <span className="text-base-2">Applicable color pallets </span>
          <Button
            // className="w-full"
            variant="outlined"
            size="small"
            onClick={() => {
              let _prevData = [...props.data.templateColorPalettes];
              _prevData.push({
                keyIndex: colorPalletKeys.new(),
                id: props.data.currentSiteColorPalette
                  ? props.data.currentSiteColorPalette.id
                  : "",
                imageUrl: props.data.siteImageURL || "",
              });

              props.onDataChange({
                templateColorPalettes: _prevData,
              });
            }}
            sx={{
              marginLeft: 2,
              backgroundColor: "#fff",
              border: "1px solid",
              borderColor: "#243060",
              color: "#243060",
              textTransform: "none",
              "&:hover": {
                backgroundColor: "#243060",
                color: "#fff",
              },
            }}
          >
            Add new color palette
          </Button>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-2">
          {props.data.templateColorPalettes &&
            props.data.templateColorPalettes
              .map((rr) => {
                return (
                  <div
                    key={rr.keyIndex}
                    className="w-full md:w-50 xl:w-60 rounded overflow-hidden shadow-lg p-4 bg-white"
                  >
                    <div className="flex flex-row">
                      <FormControl
                        sx={{ maxWidth: "100%", width: "100%" }}
                        size="small"
                      >
                        <Select
                          id="select"
                          value={rr.id}
                          onChange={(e) => {
                            // setSelectedLang(e.target.value)
                            // console.log(e.target.value);
                            // props.onDataChange({ primaryLanguage: e.target.value });
                            let _prevData = [
                              ...props.data.templateColorPalettes,
                            ];
                            let idx = _prevData.findIndex(
                              (jh) => jh.keyIndex === rr.keyIndex
                            );
                            _prevData[idx] = {
                              ..._prevData[idx],
                              id: e.target.value,
                            };

                            props.onDataChange({
                              templateColorPalettes: _prevData,
                            });
                          }}
                          className="w-full text-sm mb-2"
                        >
                          {props.allTemplateColors
                            .sort((a, b) =>
                              a.id.localeCompare(b.id, undefined, {
                                numeric: true,
                                sensitivity: "base",
                              })
                            )
                            .map((row) => {
                              return (
                                <MenuItem
                                  key={row.id}
                                  className="text-sm"
                                  value={row.id}
                                >
                                  {row.id} - ({row.category})
                                </MenuItem>
                              );
                            })}
                        </Select>

                        <ImagePicker
                          image_url={rr.imageUrl}
                          siteId={props.siteId}
                          size={"full"}
                          // title="Favicon image"
                          // desc="Favicon is displayed on your site's tab in a browser."
                          // notes="Please upload a 16x16px .PNG or .JPG image."
                          onImageChange={(img_url) => {
                            let _prevData = [
                              ...props.data.templateColorPalettes,
                            ];
                            let idx = _prevData.findIndex(
                              (jh) => jh.keyIndex === rr.keyIndex
                            );
                            _prevData[idx] = {
                              ..._prevData[idx],
                              imageUrl: img_url,
                            };

                            props.onDataChange({
                              templateColorPalettes: _prevData,
                            });
                          }}
                        />

                        <Button
                          className="w-full"
                          variant="outlined"
                          size="small"
                          onClick={() => {
                            let _prevData = [
                              ...props.data.templateColorPalettes,
                            ];
                            let idx = _prevData.findIndex(
                              (jh) => jh.keyIndex === rr.keyIndex 
                            );
                            _prevData.splice(idx, 1);

                            props.onDataChange({
                              templateColorPalettes: _prevData,
                            });
                          }}
                          sx={{
                            marginTop: 2,
                            backgroundColor: "#fff",
                            border: "1px solid",
                            borderColor: "red",
                            color: "red",
                            textTransform: "none",
                            "&:hover": {
                              backgroundColor: "red",
                              color: "#fff",
                            },
                          }}
                        >
                          Delete
                        </Button>
                      </FormControl>
                    </div>
                  </div>
                );
              })}
        </div>
      </div>
    </div>
  );
};

export default ConfigAdministration;
