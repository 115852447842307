import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";
import { Divider, Link, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { StatusCodes } from "http-status-codes";
import * as React from "react";
import { FC } from "react";
import { useCookies } from "react-cookie";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";

import axiosInstance from "../../axiosInstance";
import { log } from "console";

export const Navbar: FC = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPath = location.pathname;
  const [cookies, setCookie, removeCookie] = useCookies([
    "rp_user_data",
    "rp_user_id",
    "rp_loggedin"
  ]);

  const getQueryParam = (key) => {
    return searchParams.get(key);
  };

  React.useEffect(() => {
    async function checkCookies() {
      try {
        let response = await axiosInstance.post(`/api/check-cookies`);
        // console.log(response);
        if (response.status === StatusCodes.OK) {
          setCookie("rp_user_data", JSON.stringify(response.data.userData), {
            maxAge: 60 * 60 * 24 * 7,
            domain:
              process.env.DOMAIN ||
              window.location.hostname.split(".").slice(1).join("."),
          });
          setCookie("rp_loggedin", true, {
            maxAge: 60 * 60 * 24 * 7,
            domain: window.location.hostname.split(".").slice(1).join("."),
            sameSite: 'none',
            secure: true,
            path: "/"
          });
          setCookie("rp_user_id", response.data.userData.userId, {
            maxAge: 60 * 60 * 24 * 7,
            domain:
              process.env.DOMAIN ||
              window.location.hostname.split(".").slice(1).join("."),
          });
        } else {
          navigate("/login", { state: { removeCookies: true } });
        }
      } catch (err) {
        navigate("/login", { state: { removeCookies: true } });
      }
    }
    if (props.skipCheckCookies === true) {
    } else if (currentPath.includes("admin")) {
      if (cookies.rp_user_data?.userType !== "admin") {
        navigate(`/404?msgType=UnAuthorizedAdmin`);
      }
    } else if (
      !currentPath.includes("choose-template") &&
      !currentPath.includes("preview")
    ) {
      checkCookies();
    }
    // console.log(
    //   "currentPath",
    //   currentPath,
    //   !currentPath.includes("choose-template") &&
    //     !currentPath.includes("preview")
    // );
  }, []);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setAnchorEl(null);
    async function logoutUser() {
      try {
        // console.log("before.remove.cookies");
        // removeCookie("rp_user_data");
        // console.log("remove.rp_user_data",)
        // removeCookie("rp_user_id");
        // console.log("remove.rp_user_id",)

        setCookie("rp_user_data", "", {
          maxAge: 0,
          domain:
            process.env.DOMAIN ||
            window.location.hostname.split(".").slice(1).join("."),
        });
        setCookie("rp_loggedin", false, {
          maxAge: 0,
          domain:
            window.location.hostname.split(".").slice(1).join("."),
        });

        setCookie("rp_user_id", "", {
          maxAge: 0,
          domain:
            process.env.DOMAIN ||
            window.location.hostname.split(".").slice(1).join("."),
        });

        await axiosInstance.post("/api/logout", {}, { withCredentials: true });
      } catch (err) {
        console.log(err);
      }
    }

    logoutUser();
    // removeCookie("rp_access_token");
    navigate("/login", { state: { removeCookies: true } });

    // removeCookie_userData("rp_user_data");
  };

  return (
    <div className="flex h-[72px] sm:h-[64px] border-b border-neutral-300 top-0 bg-white py-2 px-2 items-center justify-between z-40 fixed w-full">
      <div className="w-full flex flex-row justify-between">
        <div className="flex flex-row items-center w-full">
          <img
            className="w-[172px] mr-5 hover:cursor-pointer hidden md:block"
            src={require("../../images/logo-light.svg")}
            alt=""
            onClick={() => {
              navigate("/dashboard");
            }}
          />
          <img
            className="mr-2 md:mr-5 hover:cursor-pointer block md:hidden"
            src={require("../../images/rocket-logo.svg")}
            alt=""
            onClick={() => {
              navigate("/dashboard");
            }}
          />
          {props.title !== undefined && (
            <Divider
              orientation="vertical"
              flexItem
              // className="hidden md:block"
            />
          )}

          {props.title && props.title.startsWith("Preview") && (
            <IconButton
              sx={{
                backgroundColor: "#243060",
                color: "#fff",
                borderRadius: "4px",
                height: "32px",
                width: "32px",
                "&:hover": {
                  backgroundColor: "#243060",
                  color: "#fff",
                },
              }}
              onClick={() => navigate("/choose-template")}
              className="ml-2 back-to-templates"
            >
              <ArrowBackIcon
                sx={{
                  height: "20px",
                  width: "20px",
                }}
              ></ArrowBackIcon>
            </IconButton>
          )}

          {props.title && props.title.startsWith("Choose template") && (
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#243060",
                color: "#fff",
                borderRadius: "4px",
                height: "32px",
                width: "32px",
                "&:hover": {
                  backgroundColor: "#243060",
                  color: "#fff",
                },
              }}
              onClick={() => navigate("/dashboard")}
              className={`ml-2 back-to-dashboard ${
                !cookies.rp_user_data ? "hidden" : ""
              }`}
              startIcon={
                <ArrowBackIcon
                  sx={{
                    height: "20px",
                    width: "20px",
                  }}
                ></ArrowBackIcon>
              }
            >
              <Typography
                variant="body2"
                sx={{
                  fontWeight: "bold",
                }}
              >
                Dashboard
              </Typography>
            </Button>
          )}

          <div className="flex  site-name-wrapper items-center">
            <p className={`${props.title === 'Choose template' ? 'choose-temp' : ''} ml-2 md:ml-5 text-catelina-blue md:text-lg truncate  mb-0 site-title-name `}>
              {props.title}
            </p>
 
          <div className="flex flex-row">
          <div className="mr-2">
            {!props.isPreview && !!props.siteId && (
              <>
                <Button
                  variant="contained"
                  size="small"
                  sx={{
                    backgroundColor: "#243060",
                    color: "#fff",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "#fff",
                      color: "#243060",
                    },
                  }}
                  onClick={() => {
                    let _pageId = getQueryParam("pageId");
                    let _url = `${process.env.EDITOR_ENDPOINT}/${props.siteId}`;
                    if (_pageId) {
                      _url += `/${_pageId}`;
                    }
                    window.open(_url, "_self")?.focus();
                  }}
                  className="ml-2 mr-2 editsite"
                  startIcon={<NoteAltOutlinedIcon />}
                >
                  <span className="hidden lg:block">Edit site</span>
                </Button>
                {props.isPublished && (
                  <Button
                    variant="contained"
                    size="small"
                    sx={{
                      backgroundColor: "#243060",
                      color: "#fff",
                      textTransform: "none",
                      "&:hover": {
                        backgroundColor: "#fff",
                        color: "#243060",
                      },
                    }}
                    onClick={() => {
                      const url = `/preview/${props.siteId}`;
                      window.open(url, "_blank");
                    }}
                    className="previewsite"
                    startIcon={<RemoveRedEyeOutlinedIcon />}
                  >
                    <span className="hidden lg:block">Preview</span>
                  </Button>
                )}
              </>
            )}
          </div>
            {props.isPreview && props.selectedColorPallet && (
              <div className="flex flex-row items-center">
                <div className="flex flex-row">
                  {props.colorPallets &&
                    props.colorPallets.map((row) => {
                      let colorPallet =
                        (props.colorPallets.length > 0 &&
                          props.colorPallets.find((oo) => oo.id === row.id)) ||
                        null;
                      // console.log("for each", colorPallet.colors);
                      return colorPallet ? (
                        <div
                          onClick={() => props.onColorPaletteChange(row)}
                          key={row.id}
                          className={`flex flex-row  hover:cursor-pointer`}

                          // className={`h-6 w-6 rounded-md hover:cursor-pointer ${
                          //   props.selectedColorPallet.id === row.id
                          //     ? "border-2 border-black"
                          //     : "border-2 border-gray-100"
                          // } `}
                          // style={{
                          //   backgroundColor: colorPallet
                          //     ? colorPallet.colors[0].toLowerCase()
                          //     : "",
                          // }}
                        >
                          <span
                            className={`h-g w-3 md:h-6 md:w-3 block rounded-l-full ${
                              props.selectedColorPallet.id === row.id
                                ? "border-y border-l border-black"
                                : "border-y border-l border-gray-100"
                            } `}
                            style={{
                              backgroundColor: colorPallet
                                ? colorPallet.colors[0].toLowerCase()
                                : "",
                            }}
                          />
                          <div className="flex flex-col">
                            <span
                              className={`h-3 w-3 block rounded-tr-full ${
                                props.selectedColorPallet.id === row.id
                                  ? "border-t border-r border-black"
                                  : "border-t border-r border-gray-100"
                              } `}
                              style={{
                                backgroundColor: colorPallet
                                  ? colorPallet.colors[1].toLowerCase()
                                  : "",
                              }}
                            />
                            <span
                              className={`h-3 w-3 block rounded-br-full ${
                                props.selectedColorPallet.id === row.id
                                  ? "border-b border-r border-black"
                                  : "border-b border-r border-gray-100"
                              } `}
                              style={{
                                backgroundColor: colorPallet
                                  ? colorPallet.colors[3].toLowerCase()
                                  : "",
                              }}
                            />
                          </div>
                        </div>
                      ) : null;
                    })}
                </div>
                <div className="mx-1 md:mx-2 flex flex-row">
                  <Button
                    variant="contained"
                    size="small"
                    sx={{
                      backgroundColor: "#243060",
                      color: "#fff",
                      textTransform: "none",
                      "&:hover": {
                        backgroundColor: "#fff",
                        color: "#243060",
                      },
                    }}
                    onClick={() => {
                      window.location.href = props.editUrl;
                      // window.location.reload();
                    }}
                    className="hidden lg:flex"
                    startIcon={<NoteAltOutlinedIcon />}
                  >
                    <span>Edit site</span>
                  </Button>
                  <IconButton
                    color="primary"
                    size="small"
                    onClick={() => {
                      window.location.href = props.editUrl;
                      // window.location.reload();
                    }}
                    className="p-0"
                  >
                    <NoteAltOutlinedIcon
                      sx={{
                        color: "#243060",
                        fontSize: 30,
                      }}
                      className="flex lg:hidden"
                    />
                  </IconButton>
                </div>
              </div>
            )}
            <div
              onClick={handleClick}
              className={`w-[30px] md:w-[36px] self-center hover:cursor-pointer ${
                !cookies.rp_user_data ? "hidden" : ""
              }`}
            >
              <img
                className="userprofile"
                src={require("../../images/navbar-profile.svg")}
                alt=""
              />
            </div>
            <div
              onClick={handleClick}
              className={`self-center ${cookies.rp_user_data ? "hidden" : ""}`}
            >
              <Link
                onClick={() => {
                  navigate("/login");
                }}
                className="md:flex hidden"
                sx={{
                  fontSize: "14px",
                  color: "#243060",
                  textDecoration: "none",
                  cursor: "pointer",

                  "&:hover": {
                    color: "#fe7165",
                    textDecoration: "none",
                  },
                }}
              >
                LOGIN
              </Link>
              <Link
                  onClick={() => {
                    navigate("/login");
                  }}>
                <img
                  style={{width: '30px', maxWidth: '30px'}}
                  className="userprofile md:hidden"
                  src={require("../../images/navbar-profile.svg")}
                  alt=""
                />
              </Link>
            </div>
          </div>
          </div>



        </div>


        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          className="w-60, p-0"
          sx={{
            "& .MuiPaper-root": {
              minWidth: "258px",
              width: "258px",
              border: "1px solid rgba(36, 48, 96, 0.20)",
              boxShadow: "2px 2px 8px 0px rgba(0, 0, 0, 0.25)",
            },
            "& .MuiList-root": {
              padding: 0,
            },
          }}
        >
          <MenuItem
            sx={{
              fontSize: "14px",
              padding: "10px",
              borderBottom: "1px solid rgba(36, 48, 96, 0.20)",
              backgroundColor: "#e9eaef",
              whiteSpace: "break-spaces",
              lineBreak: "anywhere",
              color: "#243060",
              lineHeight: "28px",
              "&:hover": {
                color: "#243060",
                fontWeight: 700,
                backgroundColor: "#e9eaef",
              },
              "&:focus": {
                backgroundColor: "#e9eaef",
              },
              cursor: "auto",
            }}
          >
            {cookies.rp_user_data ? cookies.rp_user_data.email : ""}
          </MenuItem>
          <MenuItem
            sx={[
              {
                fontSize: "14px",
                padding: "10px",
                borderBottom: "1px solid rgba(36, 48, 96, 0.20)",
                whiteSpace: "break-spaces",
                lineBreak: "anywhere",
                color: "#243060",
                lineHeight: "28px",
              },
              {
                "&:hover": {
                  color: "#243060",
                  fontWeight: 700,
                  backgroundColor: "transparent",
                },
              },
            ]}
            onClick={() => {
              navigate("/dashboard");
            }}
            className={currentPath === "/dashboard" ? "active" : ""}
          >
            My websites
          </MenuItem>
          <MenuItem
            sx={[
              {
                fontSize: "14px",
                padding: "10px",
                borderBottom: "1px solid rgba(36, 48, 96, 0.20)",
                whiteSpace: "break-spaces",
                lineBreak: "anywhere",
                color: "#243060",
                lineHeight: "28px",
              },
              {
                "&:hover": {
                  color: "#243060",
                  fontWeight: 700,
                  backgroundColor: "transparent",
                },
              },
            ]}
            onClick={() => {
              navigate("/account-settings");
            }}
            className={currentPath === "/account-settings" ? "active" : ""}
          >
            Account settings
          </MenuItem>
          {cookies.rp_user_data?.userType === "admin" && (
            <MenuItem
              sx={[
                {
                  fontSize: "14px",
                  padding: "10px",
                  borderBottom: "1px solid rgba(36, 48, 96, 0.20)",
                  whiteSpace: "break-spaces",
                  lineBreak: "anywhere",
                  color: "#243060",
                  lineHeight: "28px",
                },
                {
                  "&:hover": {
                    color: "#243060",
                    fontWeight: 700,
                    backgroundColor: "transparent",
                  },
                },
              ]}
              onClick={() => {
                navigate("/admin");
              }}
              className={currentPath === "/admin" ? "active" : ""}
            >
              Admin Panel
            </MenuItem>
          )}
          <MenuItem
            sx={[
              {
                fontSize: "14px",
                padding: "10px",
                whiteSpace: "break-spaces",
                lineBreak: "anywhere",
                color: "#243060",
                lineHeight: "28px",
              },
              {
                "&:hover": {
                  color: "#243060",
                  fontWeight: 700,
                  backgroundColor: "transparent",
                },
              },
            ]}
            onClick={handleLogout}
          >
            Sign out
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
};
