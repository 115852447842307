import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import * as React from "react";
import { useParams } from "react-router-dom";
import axiosInstance from "../../axiosInstance";
import ConfigActivePlans from "./ConfigActivePlans";
import ConfigBillings from "./ConfigBillings";
import ConfigPricingPlan from "./ConfigPricingPlan";

const plans =
  process.env.NODE_ENV === "production"
    ? [
        {
          plan: "Forever Free",
          duration: "month",
          price: 0,
          priceId: "",
        },
        {
          plan: "Forever Free",
          duration: "year",
          price: 0,
          priceId: "",
        },
        {
          plan: "Pro",
          duration: "month",
          price: 1.99,
          priceId: "price_1Pvf51H46TpgZNWblJh02txG",
        },
        {
          plan: "Pro",
          duration: "year",
          price: 9.99,
          priceId: "price_1Pvf6PH46TpgZNWbw1eLDIwX",
        },
        {
          plan: "Business",
          duration: "month",
          price: 14.99,
          priceId: "price_1PvfACH46TpgZNWbedRmHjX2",
        },
        {
          plan: "Business",
          duration: "year",
          price: 99.99,
          priceId: "price_1Pvf9TH46TpgZNWbRUkC8Lhd",
        },
      ]
    : [
        {
          plan: "Forever Free",
          duration: "month",
          price: 0,
          priceId: "",
        },
        {
          plan: "Forever Free",
          duration: "year",
          price: 0,
          priceId: "",
        },

        {
          plan: "Pro",
          duration: "month",
          price: 1.99,
          priceId: "price_1PNqPdH46TpgZNWbBVrkmf4g",
        },
        {
          plan: "Pro",
          duration: "year",
          price: 9.99,
          priceId: "price_1PNqHLH46TpgZNWbKB04sa01",
        },
        {
          plan: "Business",
          duration: "month",
          price: 14.99,
          priceId: "price_1PNro9H46TpgZNWbbeye2dYT",
        },
        {
          plan: "Business",
          duration: "year",
          price: 99.99,
          priceId: "price_1PNrmZH46TpgZNWb7iAkJzba",
        },
      ];

const ConfigSubscriptions = () => {
  const [currentTab, setCurrentTab] = React.useState("1");
  const [isLoading, setIsLoading] = React.useState(true);
  const [isLoadingInvoices, setIsLoadingInvoices] = React.useState(true);
  const [invoices, setInvoices] = React.useState([]);
  const [latestInvoice, setLatestInvoice] = React.useState();
  const [allSubs, setAllSubs] = React.useState([]);
  const [activeSub, setActiveSub] = React.useState();
  const [upcomingPhase, setUpcomingPhase] = React.useState();
  const { id } = useParams();

  // React.useEffect(() => {
  //   console.log("node env", process.env.NODE_ENV)
  // },[])
  async function getAllSubscriptions() {
    try {
      setIsLoading(true);
      setIsLoadingInvoices(true);
      let res = await axiosInstance.get(
        `/api/configs/site-config-get-subscriptions?siteId=${id}`
      );
      // console.log("getAllSubscriptions", res.data.data);
      setAllSubs(res.data.data);
      const activeSubscription = res.data.data.find(
        (sub) => sub.subscription_status !== "canceled"
      );
      if (activeSubscription) {
        activeSubscription.selectedPlan = plans.find(
          (plan) => plan.priceId === activeSubscription.plan_id
        );
      }
      setActiveSub(activeSubscription);

      let upcomingPlan;
      if (
        activeSubscription?.scheduleData &&
        activeSubscription.scheduleData.phases?.length > 1
      ) {
        const upcomingSubPhase = activeSubscription.scheduleData.phases.find(
          (phase) => phase.start_date > Date.now() / 1000
        );
        // const upcomingSubPhase = activeSubscription.scheduleData.phases[1];
        if (upcomingSubPhase) {
          upcomingSubPhase.selectedPlan = plans.find(
            (plan) => plan.priceId === upcomingSubPhase.items[0].price
          );
          upcomingPlan = upcomingSubPhase;
        }
      }

      setUpcomingPhase(upcomingPlan);
      setIsLoading(false);

      let subscriptions: string[] = [];
      res.data.data.forEach((row) => {
        subscriptions.push(row.subscriptionId);
      });

      if (!subscriptions.length) {
        setIsLoadingInvoices(false);
        return;
      }
      let resInvoices = await axiosInstance.post(
        `/api/configs/site-config-get-invoices`,
        { subscriptions: subscriptions }
      );
      // console.log("getAllSubscriptions", resInvoices.data.data);
      setInvoices(resInvoices.data.data);

      if (activeSubscription) {
        setLatestInvoice(
          resInvoices.data.data
            .filter(
              (invoice) =>
                invoice.subscription === activeSubscription.subscriptionId
            )
            .sort((a, b) => b.created - a.created)[0]
        );
      }
      setIsLoadingInvoices(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  }

  React.useEffect(() => {
    // setLoggedUserData(JSON.parse(cookies.rp_user_data))
    getAllSubscriptions();
  }, []);

  return (
    <TabContext value={currentTab}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <TabList
          onChange={(e, newValue) => setCurrentTab(newValue)}
          aria-label="lab API tabs example"
        >
          <Tab label="My Plan(s)" value="1" />
          <Tab label="Invoices" value="2" />
          <Tab label="Pricing Plans" value="3" />
        </TabList>
      </Box>
      <TabPanel 
        style={{paddingLeft: 0, paddingRight: 0}}
      value="1">
        <ConfigActivePlans
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          activeSub={activeSub}
          updateActiveSub={setActiveSub}
          reFetch={getAllSubscriptions}
          upcomingPhase={upcomingPhase}
          latestInvoice={latestInvoice}
        />
      </TabPanel>
      <TabPanel style={{paddingLeft: 0, paddingRight: 0}} value="2">
        <ConfigBillings isLoading={isLoadingInvoices} invoices={invoices} />
      </TabPanel>
      <TabPanel style={{paddingLeft: 0, paddingRight: 0}} value="3">
        <ConfigPricingPlan
          id={id}
          plans={plans}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          activeSub={activeSub}
          updateActiveSub={setActiveSub}
          reFetch={getAllSubscriptions}
          upcomingPhase={upcomingPhase}
        />
      </TabPanel>
    </TabContext>
  );
};

export default ConfigSubscriptions;
