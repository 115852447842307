import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ReceiptSharpIcon from '@mui/icons-material/ReceiptSharp';
import { Button, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import * as React from "react";
import { useParams } from "react-router-dom";
import axiosInstance from "../../axiosInstance";
import SubscriptionCard from "./SubscriptionCard";

const ConfigActivePlans = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const [showConfirmation, setShowConfirmation] = React.useState(false);
  const [showPlanSwitchConfirmation, setShowPlanSwitchConfirmation] = React.useState(false);
  const [isCancelling, setIsCancelling] = React.useState(false);

  const handleDelete = () => {
    onCancelSubs(props.activeSub.subscriptionId); // Call the delete function passed as a prop
    setShowConfirmation(false); // Close the confirmation dialog after delete
  };

  const onCancelSubs = async (subsId) => {
    setIsCancelling(true);
    // console.log('onCancelSubs', subsId);
    let l_data = {
      siteId: id,
      subscriptionId: subsId,
    };

    let rs = await axiosInstance.put(
      "/api/configs/site-config-cancel-subscription",
      l_data
    );

    // console.log("api cancel res", rs.data);
    if (!rs.data.error && rs.data.status && rs.data.subscription.cancel_at_period_end) {
      enqueueSnackbar("Subscription cancelled successfully.", {
        variant: "success",
        className: "bg-catelina-blue",
      });
      setIsCancelling(false);
      props.reFetch();
    } else if (rs.data.error) {
      enqueueSnackbar('Something went wrong while cancelling subscription', {
        variant: "error",
        autoHideDuration: 9000,
      });
      setIsCancelling(false);
    }
    // console.log("api cancel res", rs);
  };

  const onCancelUpcomingSubs = async () => {
    setShowPlanSwitchConfirmation(false);
    setIsCancelling(true);
    // console.log('onCancelSubs', props.activeSub.subscriptionId);
    let l_data = {
      siteId: id,
      subscriptionId: props.activeSub.subscriptionId,
    };

    let rs = await axiosInstance.put(
      "/api/configs/site-config-cancel-upcoming-subscription",
      l_data
    );

    // console.log("api cancel res", rs.data);
    if (!rs.data.error && rs.data.subscription) {
      enqueueSnackbar("Upcoming Subscription cancelled successfully.", {
        variant: "success",
        className: "bg-catelina-blue",
      });
      setIsCancelling(false);
      props.reFetch();
    } else if (rs.data.error) {
      enqueueSnackbar(rs.data.error ?? 'Something went wrong while cancelling upcoming subscription', {
        variant: "error",
        autoHideDuration: 9000,
      });
      setIsCancelling(false);
    }
    // console.log("api cancel res", rs);
  };

  return (
    <div className="flex flex-col justify-center items-left w-full">
      {showConfirmation && (
        <div className="fixed inset-0 overflow-y-auto flex justify-center items-center z-10">
          <div className="fixed inset-0">
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>
          
          <div className="bg-white rounded py-8 py-8 md:px-16 m-4 z-50 flex items-center flex-col relative">
          <img src={require("../../images/close.svg")} className="absolute right-2 top-2 cursor-pointer" onClick={() => setShowConfirmation(false)} />
          <img src={require("../../images/check-icon.png")} className="mb-2" />
            <span className="text-gray-800 mb-0">
              Are you sure you want to cancel subscription?
            </span>
            <span>
              This action will take effect at the end of your current billing cycle, and you will lose access to all features that comes with it.
            </span>
            {!!props.upcomingPhase && <span className="font-semibold text-black">This will also cancel upcoming plans immediately.</span>}
            <div className="flex justify-end flex-col sm:flex-row mt-6"> 
              <button
                className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                onClick={handleDelete}
              >
                Confirm Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      {showPlanSwitchConfirmation && (
        <div className="fixed inset-0 overflow-y-auto flex justify-center items-center z-10">
          <div className="fixed inset-0">
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>
          <div className="bg-white mx-w-xl rounded py-8 px-8 md:px-16  m-4 z-50 flex items-center flex-col relative">
            <img src={require("../../images/close.svg")} className="absolute right-2 top-2 cursor-pointer" onClick={() => setShowPlanSwitchConfirmation(false)} />
            <img src={require("../../images/check-icon.png")}  />
            <h4 className="mb-4">Cancel upcoming subscription</h4>
            <p className="text-gray-800 mb-3 text-center">
              Are you sure you want to cancel upcoming subscription and stay on current active one?
            </p>
            <p className="text-center mb-4 text-grey-primary font-normal w-2/3">This action will take effect immediately.</p>
            <div className="flex justify-end flex-col sm:flex-row">
              <button
                className="rounded-md bg-red-600 hover:bg-red-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                onClick={onCancelUpcomingSubs}
              >
                Confirm Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {/* <h2 className="my-4">Billing</h2> */}
      <div className="w-full flex flex-col">
        {props.isLoading && (
          <div className="bg-white border rounded-lg shadow-md p-4 w-full h-64 flex flex-col justify-center text-center">
            <ReceiptSharpIcon className="self-center mb-2" />
            <h3 className="text-xl font-bold mb-0 mt-0">Fetching Subscriptions...</h3>
          </div>
        )}
        {!props.isLoading && !props.activeSub && (
          <div className="bg-white border rounded-lg shadow-md p-4 w-full h-64 flex flex-col justify-center text-center">
            <ReceiptSharpIcon className="self-center mb-2" />
            <h3 className="text-xl font-bold mb-0 mt-0">No active subscription found</h3>
          </div>
        )}
        {!props.isLoading && <div className="w-full">
          {props.activeSub && (
            <SubscriptionCard
              data={props.activeSub}
              upcomingPhase={props.upcomingPhase}
              latestInvoice={props.latestInvoice}
              isCancelling={isCancelling}
              onCancel={(subId) => {
                setShowConfirmation(true);
              }}
            />
          )}

          {props.upcomingPhase && <div>
            <h4 className="mb-2">Upcoming Plan</h4>
            <div className="bg-white border border-[#E1E4EF] p-4 rounded-md">
              <div className="flex sm:items-center items-start justify-between flex-col sm:flex-row mb-2 sm:mb-0">
                <p className="font-bold mb-0.5 text-grey-primary">{props.upcomingPhase.selectedPlan?.plan} ({props.upcomingPhase.selectedPlan?.duration}ly)</p>
                <p className="font-bold mb-0.5 text-grey-primary">${props.upcomingPhase.selectedPlan?.price}/site/{props.upcomingPhase.selectedPlan?.duration}</p>
              </div>
              <p className="mb-1 font-normal">Valid for 1 {props.upcomingPhase.selectedPlan?.duration}</p>

              <div className="flex justify-between mt-6 flex-col lg:flex-row">
                <div className="bg-[#E1E4EF] rounded p-2 mb-0 flex items-center">
                  <p className="text-sm mb-0 flex items-center text-catelina-blue  flex-wrap md:flex-noWrap">
                    <InfoOutlinedIcon className="mr-1" style={{ fontSize: 20 }} /> 
                    Attention: 
                    <span className="text-grey-primary ml-1 font-normal">Your upcoming {props.upcomingPhase.selectedPlan?.plan} ({props.upcomingPhase.selectedPlan?.duration}ly) plan will be auto-updated post current plan expires.</span>
                  </p>
                </div>

                <Button
                  className="shadow-none lg:mt-0 mt-2 rounded-md bg-red-600 lg:ml-2"
                  disabled={isCancelling}
                  size="large"
                  variant="contained"
                  color="error"
                  onClick={() => setShowPlanSwitchConfirmation(true)}
                >
                  <Typography textTransform='capitalize'>Cancel {(props.activeSub.amount_total / 100) > props.upcomingPhase.selectedPlan.price ? 'Downgrade' : 'Upgrade'}</Typography>
                </Button>
              </div>
            </div>
          </div>}
        </div>}
      </div>
    </div>
  );
};

export default ConfigActivePlans;